import { combineReducers } from 'redux';
import { productListingReducer } from './productListing.reducer';
import { countryListReducer } from './countryList.reducer';
import { countryReducer } from './country.reducer';
import { setCoordinatesReducer } from './setCoordinates.reducer';
import { zipCodeReducer } from './zipCode.reducer';
import { vendorReducer } from './vendors.reducer';
import { productReducer } from './product.reducer';
import { geoCodeReducer } from './geoCode.reducer';
import { locationReducer } from './locations.reducer';
import { countryCodeReducer } from './countryCode.reducer';
import { filterTypeReducer } from './filterType.reducer';
import { productDetailsReducer } from './productDetails.reducer';
import { locateDetailReducer } from './locationDetail.reducer';
import { locationIdReducer } from './locationID.reducer';
import { sliderDistReducer } from '../slider/slider.reducers';
import { setCoordLocationReducer } from './coordsLocation.reducer';
import { locationDirectionsReducer } from './locationDirections.reducer';
import { directionReducer } from './direction.reducer';
import { directionCoordsReducer } from './directionCoords.reducer';
import { locationsPageNumReducer } from '../locationsPageNum/locationsPageNum.reducer';
import { mapReducer } from './map.reducer';
import { hMapReducer } from './hMap.reducer';
import { mapUIReducer } from './mapUI.reducer';
import { locationIdMapReducer } from './locationIDMap.reducer';
import { mapLocGroupReducer } from './mapLocGroup.reducer';
import { tabReducer } from './tab.reducer';


const rootReducer = combineReducers({
    productListingReducer,
    countryListReducer,
    countryReducer,
    setCoordinatesReducer,
    zipCodeReducer,
    vendorReducer,
    productReducer,
    geoCodeReducer,
    locationReducer,
    countryCodeReducer,
    filterTypeReducer,
    productDetailsReducer,
    locateDetailReducer,
    locationIdReducer,
    sliderDistReducer,
    setCoordLocationReducer,
    locationDirectionsReducer,
    directionReducer,
    directionCoordsReducer,
    locationsPageNumReducer,
    mapReducer,
    hMapReducer,
    mapUIReducer,
    locationIdMapReducer,
    mapLocGroupReducer,
    tabReducer
});

export default rootReducer;